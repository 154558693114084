@import "~ag-grid-enterprise/dist/styles/ag-grid.scss";
@import "~ag-grid-enterprise/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-metronic {
	@include ag-theme-alpine((
			// Colors
			alpine-active-color: $primary,
			foreground-color: $dark,
			header-foreground-color: $text-muted,
			header-background-color: get($datatable-config, bg),
			row-hover-color: get($datatable-config, row-bg-active),
			selected-row-background-color: get($datatable-config, row-bg-active),
			odd-row-background-color: ag-derived(header-background-color),
			subheader-background-color: ag-derived(header-background-color),
			data-color: $dark,
			border-color: get($datatable-config, row-border),
			control-panel-background-color: $white,
			toggle-button-border-width: 2px,
			toggle-button-off-background-color: get($switch-config, color),
			toggle-button-off-border-color: ag-derived(toggle-button-off-background-color),
			toggle-button-on-border-color: ag-derived(toggle-button-off-background-color),
			toggle-button-on-background-color: ag-derived(toggle-button-off-background-color),
			input-border-color: $input-border-color,
			input-disabled-border-color: ag-derived(input-border-color),
			input-disabled-background-color: $input-disabled-bg,
			
			// Borders
			border-radius: $border-radius,
			
			// Sizing
			row-height: 60px,
			cell-horizontal-padding: 16px,
			toggle-button-width: 38px,
			toggle-button-height: 24px,
			input-focus-border-color: $input-focus-border-color,
			input-focus-box-shadow: none,
			
			// Fonts
			font-family: $font-family-base,
			font-size: $root-font-size,
			icon-font-family: "Font Awesome 5 Free",
			icons-data: null,
			icons-font-codes: (
					aggregation: unquote("\"\\") + unquote("f247\""),
					arrows: unquote("\"\\") + unquote("f0b2\""),
					asc: unquote("\"\\") + unquote("f062\""),
					cancel: unquote("\"\\") + unquote("f057\""),
					chart: unquote("\"\\") + unquote("f080\""),
					color-picker: unquote("\"\\") + unquote("f576\""),
					columns: unquote("\"\\") + unquote("f0db\""),
					contracted: unquote("\"\\") + unquote("f146\""),
					copy: unquote("\"\\") + unquote("f0c5\""),
					cross: unquote("\"\\") + unquote("f00d\""),
					desc: unquote("\"\\") + unquote("f063\""),
					expanded: unquote("\"\\") + unquote("f0fe\""),
					eye-slash: unquote("\"\\") + unquote("f070\""),
					eye: unquote("\"\\") + unquote("f06e\""),
					filter: unquote("\"\\") + unquote("f0b0\""),
					first: unquote("\"\\") + unquote("f100\""),
					grip: unquote("\"\\") + unquote("f58e\""),
					group: unquote("\"\\") + unquote("f5fd\""),
					last: unquote("\"\\") + unquote("f101\""),
					left: unquote("\"\\") + unquote("f060\""),
					linked: unquote("\"\\") + unquote("f110\""),
					loading: unquote("\"\\") + unquote("f119\""),
					maximize: unquote("\"\\") + unquote("f2d0\""),
					menu: unquote("\"\\") + unquote("f0c9\""),
					minimize: unquote("\"\\") + unquote("f2d1\""),
					next: unquote("\"\\") + unquote("f105\""),
					none: unquote("\"\\") + unquote("f338\""),
					not-allowed: unquote("\"\\") + unquote("f05e\""),
					paste: unquote("\"\\") + unquote("f0ea\""),
					pin: unquote("\"\\") + unquote("f276\""),
					pivot: unquote("\"\\") + unquote("f074\""),
					previous: unquote("\"\\") + unquote("f104\""),
					right: unquote("\"\\") + unquote("f061\""),
					save: unquote("\"\\") + unquote("f0c7\""),
					small-down: unquote("\"\\") + unquote("f107\""),
					small-left: unquote("\"\\") + unquote("f104\""),
					small-right: unquote("\"\\") + unquote("f105\""),
					small-up: unquote("\"\\") + unquote("f106\""),
					tick: unquote("\"\\") + unquote("f00c\""),
					tree-closed: unquote("\"\\") + unquote("f105\""),
					tree-indeterminate: unquote("\"\\") + unquote("f068\""),
					tree-open: unquote("\"\\") + unquote("f107\""),
					unlinked: unquote("\"\\") + unquote("f127\"")
			),
			
			// Misc
			card-shadow: $dropdown-box-shadow,
			popup-shadow: ag-derived(card-shadow),
			selected-tab-underline-width: 1px
	));
	
	// Typography
	label {
		margin-bottom: 0;
	}
	
	// Icon
	.ag-icon {
		font-weight: 900;
	}
	
	.ag-drag-handle,
	.ag-drag-handle .ag-icon,
	.ag-header-icon .ag-icon {
		color: $text-muted;
	}
	
	.ag-header-icon {
		margin-top: -2px;
	}
	
	// Button
	.ag-standard-button {
		font-weight: 400;
		transition: $transition-link;
		
		&:hover,
		&:active {
			background-color: $primary;
			color: $white;
		}
	}
	
	// Table
	.ag-header-group-text,
	.ag-header-cell-text {
		font-size: get($datatable-config, head-compact-font-size);
		text-transform: get($datatable-config, head-compact-text-transform);
		font-weight: get($datatable-config, head-compact-font-weight);
		letter-spacing: get($datatable-config, head-compact-letter-spacing);
	}
	
	.ag-row {
		font-size: 1rem;
	}
	
	.ag-cell {
		-webkit-font-smoothing: antialiased;
	}
	
	// Input
	input[class^='ag-']:not([type]),
	input[class^='ag-'][type='text'],
	input[class^='ag-'][type='number'],
	input[class^='ag-'][type='tel'],
	input[class^='ag-'][type='date'],
	input[class^='ag-'][type='datetime-local'],
	textarea[class^='ag-'] {
		transition: $transition-input;
	}
	
	// Checkbox
	.ag-checkbox-input-wrapper {
		background: get($checkbox-config, types, solid, theme, base-color);
		width: get($checkbox-config, types, solid, sizes, default, base);
		height: get($checkbox-config, types, solid, sizes, default, base);
		border: 1px solid transparent;
		
		&::after {
			content: "";
			display: none;
			width: 5px;
			height: 10px;
			border-color: $white;
			border-style: solid;
			border-width: 0 2px 2px 0;
			transform: translate(-50%, -50%) rotate(45deg);
		}
		
		&.ag-checked {
			background: $primary;
			border-color: $primary;
			
			&::after {
				content: "";
				display: block;
				top: 50%;
				left: 50%;
			}
		}
		
		&:focus-within {
			box-shadow: none;
		}
	}
	
	// Switch
	.ag-toggle-button-input-wrapper {
		&::before {
			opacity: 0.7;
			transition: all 100ms ease-in;
		}
		
		&.ag-checked {
			&::before {
				left: calc(100% - 22px);
				background-color: get($switch-config, tick);
				opacity: 1;
			}
		}
		
		&:focus-within {
			box-shadow: none;
		}
	}
}