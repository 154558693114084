//
// Toastr
//




// Base
.Toastify__toast {
    min-height: 0;
    opacity: 0.9;
}

.Toastify__toast-container {

  // Title
  .Toastify__toast-body {
    font-family: Poppins, Helvetica, "sans-serif";
    margin: 0.5rem !important;
    font-size: 1rem;
    font-weight: 500;
  }

  // States
  .Toastify__toast--success {
    background-color: $success;
    color: $success-inverse;
  }

  .Toastify__toast--info {
    background-color: $info;
    color: $info-inverse;
  }

  .Toastify__toast--warning {
    background-color: $warning;
    color: $warning-inverse;
  }

  .Toastify__toast--error {
    background-color: $danger;
    color: $danger-inverse;
  }

  .Toastify__close-button {
    display: none;
  }

}
